'use client';
import { Fragment } from 'react';

import classNames from 'classnames';

import { DeserializedStorePageRow, STORE_PAGE_ID, StorePageId } from '@api';

import { getMixPanelPage, MIXPANEL_EVENT_NAMES, MixpanelEventName } from '@shared/api/mixPanel';
import Preloader from '@shared/common/components/Preloader';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { useTrackMixPanel } from '@shared/common/providers/MixPanelProvider';
import { useStoreDataQuery } from '@shared/hooks/query/store';

import PageHeader from '@store:web/components/layout/PageHeader/PageHeader';

import { useStoreChain } from '../../providers/StoreChainProvider';
import StoreBlock, { isAppsBlock } from './StoreBlock';

export interface StorePageProps {
  page: StorePageId;
}

const getHasRowDivider = (rows: DeserializedStorePageRow[], rowIndex: number) => {
  const row = rows[rowIndex];

  return (
    (row.attributes.type !== 'SMALL' ||
      row.blocks.some((block) => block.attributes.type.startsWith('APP'))) &&
    rowIndex !== rows.length - 1 &&
    (rows[rowIndex + 1].attributes.type !== 'SMALL' ||
      rows[rowIndex + 1].blocks.some((block) => block.attributes.type.startsWith('APP')))
  );
};

const MAX_SECTIONS_WITH_H2_TITLE = 2;

const MIXPANEL_PAGE_TO_EVENT_MAP: Record<StorePageId, MixpanelEventName> = {
  [STORE_PAGE_ID.discover]: MIXPANEL_EVENT_NAMES.STORE_PAGE_VIEW,
  [STORE_PAGE_ID.apps]: MIXPANEL_EVENT_NAMES.APPS_PAGE_VIEW,
  [STORE_PAGE_ID.games]: MIXPANEL_EVENT_NAMES.GAMES_PAGE_VIEW,
  [STORE_PAGE_ID.upcoming]: MIXPANEL_EVENT_NAMES.UPCOMING_PAGE_VIEW,
};

const StorePage = ({ page }: StorePageProps) => {
  const { user } = useAuth();
  const { chain } = useStoreChain();
  const { data, isPending: isLoading } = useStoreDataQuery({
    chain: chain?.name,
    page,
  });
  let sectionsWithH2Title = 0;

  const mixPanelPageName = getMixPanelPage();

  useTrackMixPanel(MIXPANEL_PAGE_TO_EVENT_MAP[page], {
    distinct_id: user?.id as number,
    page: mixPanelPageName,
  });

  return (
    <main className="overflow-hidden">
      {isLoading && <Preloader />}

      <PageHeader page={page} />

      <div className="v2-container pt-1 flex flex-col gap-5 md:gap-8">
        {data?.rows.map((row, rowIndex, rows) => {
          if (!row.blocks.length) {
            return null;
          }

          const hasHorizontalScroll = row.attributes.type === 'SMALL';
          const hasRowDivider = getHasRowDivider(rows, rowIndex);

          return (
            <Fragment key={row.id}>
              {hasHorizontalScroll && (
                <div className="n-store-container-px overflow-hidden">
                  <div className="flex overflow-x-auto -mx-2 md:-mx-3 hide-scrollbar store-container-px">
                    {row.blocks.map((block) => {
                      return (
                        <div
                          className={classNames(
                            'flex-shrink-0 h-auto px-2 md:px-3 flex w-[calc(100%-14px)] flex-col',
                            {
                              'md:w-1/2': row.blocks.length === 2,
                              'md:w-[calc(50%-12px)] lg:w-[calc(50%-24px)] 2xl:w-1/3':
                                row.blocks.length >= 3,
                              'self-center': ['BANNER', 'BIG_APP', 'CAROUSEL'].includes(
                                block.attributes.type,
                              ),
                            },
                          )}
                          key={block.id}
                        >
                          <StoreBlock
                            imagePriority={rowIndex <= 2}
                            page={page}
                            block={block}
                            className="flex-1"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {!hasHorizontalScroll && (
                <div key={row.id} className="flex gap-5 md:gap-8 2xl:[&>*]:flex-1 max-2xl:flex-col">
                  {row.blocks.map((block, blockIndex) => {
                    const hasBlockDivider =
                      blockIndex !== row.blocks.length - 1 &&
                      !['BANNER', 'BIG_APP', 'CAROUSEL'].includes(
                        row.blocks[blockIndex + 1].attributes.type,
                      );
                    const hasH2Title =
                      isAppsBlock(block) && sectionsWithH2Title < MAX_SECTIONS_WITH_H2_TITLE;

                    if (hasH2Title) {
                      ++sectionsWithH2Title;
                    }

                    return (
                      <Fragment key={block.id}>
                        <StoreBlock
                          titleTag={hasH2Title ? 'h2' : 'span'}
                          imagePriority={rowIndex <= 2}
                          page={page}
                          block={block}
                        />

                        {hasBlockDivider && <hr className="border-neutral-600/10 2xl:hidden" />}
                      </Fragment>
                    );
                  })}
                </div>
              )}

              {hasRowDivider && <hr className="border-neutral-600/10" />}
            </Fragment>
          );
        })}
      </div>
    </main>
  );
};

export default StorePage;
